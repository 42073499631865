"use client";

//
// errors.tsx
//

import { CircleBrandMark } from "@layouts/ApplicationLayout/CircleBrandMark";
import {
  DecorationBottomLeft,
  DecorationBottomRight,
  DecorationTopLeft,
  DecorationTopRight,
} from "@layouts/svg-illustration/sing-in-decoration";
import Link from "next/link";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  // Log the error to an error reporting service
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  //render
  return (
    <div className="tw-relative tw-h-screen tw-overflow-hidden">
      <div className=" tw-absolute  tw-left-6 tw-top-6 tw-z-[51]">
        <CircleBrandMark />
      </div>
      <div className="tw-relative tw-z-50 tw-flex tw-h-full tw-items-center tw-justify-center tw-overflow-auto  tw-p-6">
        <div className="tw-max-w-[22.875rem]">
          <section>
            <div className="tw-text-center tw-font-sans tw-text-h4-desk">
              Something went wrong!
            </div>
            <div className="tw-mt-3 tw-text-center tw-font-sans tw-text-14px-light">
              {error.message}
            </div>
            <div className="tw-mt-8 tw-flex tw-items-center tw-justify-center">
              <div
                aria-hidden="true"
                className="tw-h-px tw-w-full tw-bg-lightGrey"
                data-orientation="horizontal"
                role="separator"
              ></div>
              <Link href="/">
                <div className="tw-text-14px-med tw-mx-2 tw-text-nowrap tw-px-2 tw-py-1 tw-text-mediumblue focus-visible:tw-rounded-custom-xl focus-visible:tw-border-0 focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-black">
                  Back to home page
                </div>
              </Link>
              <div
                aria-hidden="true"
                className="tw-h-px tw-w-full tw-bg-lightGrey"
                data-orientation="horizontal"
                role="separator"
              ></div>
            </div>
          </section>
        </div>
      </div>
      <DecorationTopLeft />
      <DecorationBottomLeft />
      <DecorationTopRight />
      <DecorationBottomRight />
    </div>
  );
}
